import { reactive } from "vue";
import { getNetList } from "@/request/cityGridApi";
import { ElLoading } from 'element-plus';

export default (indexState) => {
    const state = reactive({
        query: {
            pageNum: 1,
            pageSize: 10,
        },
        total: 0,
        tableData: [],
    })

    // 获取列表
    async function getTableData() {
        const params = { ...indexState.query, ...indexState.mapQuery, ...state.query };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getNetList(params);
        state.total = data?.total || 0;
        state.tableData = data?.list || [];
        loadingInstance && loadingInstance.close()
    }

    // 清空所有table/页码重置
    function clearTable() {
        state.query.pageNum = 1;
        state.total = 0;
        state.tableData = [];
    }

    // 从热力图数据中获取前十条
    function setTableData(data, total) {
        clearTable();
        state.tableData = data;
        state.total = total;
    }
    return { state, getTableData, clearTable, setTableData }
}