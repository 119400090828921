/**
 * api统一管理
 */
import { post } from './http';

/**
 * 城市网格
 */
// 字典查询
export const getNetDict = (p) => post('/api/net/dict', p);
// 获取城市列网格列表
export const getNetList = (p) => post('/api/net/list', p);
// 导出
export const netExport = () => `/api/net/export`;






