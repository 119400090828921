<template>
    <div class="cover_table">
        <div class="radio_box">
            <el-radio-group :model-value="sortField" @change="sortChange" size="small" :disabled="list.length === 0">
                <el-radio-button label="shop_num">按门店数</el-radio-button>
                <el-radio-button label="close_shop_num">按关店数</el-radio-button>
                <el-radio-button label="new_shop_num">按新店数</el-radio-button>
                <el-radio-button label="comments_num">按评论数</el-radio-button>
            </el-radio-group>
        </div>
        <div class="table_box">
            <el-table ref="tableRef" :data="list" border highlight-current-row @row-click="handleRowClick" size="small">
                <el-table-column type="index" label="排序" width="50" align="center" />
                <el-table-column prop="shop_num" label="门店数" align="center" />
                <el-table-column prop="close_shop_num" label="关店数" align="center" />
                <el-table-column prop="new_shop_num" label="新店数" align="center" />
                <el-table-column prop="comments_num" label="评论数" align="center" />
            </el-table>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const tableRef = ref(null);

const props = defineProps({
    sortField: String,
    list: { type: Array, defualt: [] },
    activeRow: { type: Object, defualt: null }
})

const emits = defineEmits(['update:sortField', 'onTable'])

// 排序改变
function sortChange(val) {
    emits('update:sortField', val)
}

// 单选
function handleRowClick(row) {
    const i = props.list.findIndex(item => item === row)
    emits('onTable', i)
}

watch(() => props.activeRow, (newVal) => {
    tableRef.value.setCurrentRow(newVal || null)
})
</script>

<style lang="scss" scoped>
.cover_table {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 2;
    padding: 15px;
    width: 405px;
    // min-height: 500px;
    box-shadow: 0 0 10px #ccc;

    .radio_box {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
    }
}
</style>