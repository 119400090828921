import { reactive } from "vue";
import { getNetList } from "@/request/cityGridApi.js";
import { ElLoading, ElMessage } from 'element-plus';

export default (indexState, mapRef, setTableData) => {
    const state = reactive({
        query: {
            pageNum: 1, pageSize: 30000
        },
        hotData: [],
        top10: [],
        total: 0,
        activeRow: null// 选中点对象
    })

    // 获取热力图数据
    async function getHotData() {
        const loading = ElLoading.service({ text: '努力搜索中，请稍后...' });
        const params = { ...indexState.query, ...indexState.mapQuery, ...state.query }
        const { data } = await getNetList(params);
        state.total = data.total || 0;
        state.hotData = data.list || [];
        if (state.hotData.length > 0) {
            setHotMap(state.hotData)
            getTop10(state.hotData)
        } else {
            ElMessage.error('暂无数据');
            state.top10 = [];// 清空前十数据
            setTableData([], 0);// 清空详细数据
            mapRef.value.resetMap();
        }
        loading.close();
    }

    // 获取前十-设置地图中心点-打点
    async function getTop10(data) {
        state.top10 = data.slice(0, 10);
        // 同步表格第一页数据
        setTableData(state.top10, state.total)
        // 设置地图中心点
        const row = state.top10[0];
        mapRef.value.setMapCenter(row.center_lon, row.center_lat)
        // 打点
        setMakers(state.top10)
    }

    // 打点-整理打点数据并调用地图组件中的打点方法
    function setMakers(data, i) {
        const points = data.map((item, index) => {
            item['myActive'] = i === index;// 加入默认选中值为false
            return item;
        })
        state.top10 = points;
        mapRef.value.addMakers(points)
    }

    // 整理热力图数据，并调用地图渲染热力图
    function setHotMap(data = []) {
        const points = data.map(item => {
            return {
                lng: item.lng_center,
                lat: item.lat_center,
                count: item[indexState.mapQuery.sortField]
            }
        })
        mapRef.value.addHeatMap(points)
    }

    // 点击表格
    function onTable(index) {
        onClickHandle(index, 'Y')
    }

    // 点击标记
    function onMaker(index) {
        onClickHandle(index)
    }

    // 点击事件处理
    function onClickHandle(index, center) {
        const row = state.top10[index];
        if (state.activeRow === row) {// 点击相同的
            setMakers(state.top10);
            state.activeRow = null;
        } else {// 点击不同的
            setMakers(state.top10, index);
            state.activeRow = row;
            (center === 'Y') && mapRef.value.setMapCenter(row.lng_center, row.lat_center);
            addInfoWindow()
        }
    }

    // 打开信息框
    function addInfoWindow() {
        const row = state.activeRow;
        mapRef.value.addInfoWindow(row)
    }

    return { state, getHotData, setMakers, onTable, onMaker }
}