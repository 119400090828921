import { onBeforeMount, reactive } from "vue";
import { getNetDict } from '@/request/cityGridApi.js';

// 参数选项
export default ({ query }) => {
    const state = reactive({
        batchList: [],//批次
        provinceList: [],// 省份
        cityList: [],// 城市
        districtList: [],// 区县
        streetList: [],//街道
        cuisineList: [],// 餐饮类型-菜系1级
        cuisine1List: [],// 菜系类型-菜系2级
        cuisineTypeList: [],// 菜系-菜系3级
        loading: {
            batch: false,
            province: false,
            city: false,
            district: false,
            street: false,
            mainType: false,
            cuisineType: false,
            cuisine: false
        }
    })
    // 获取批次
    async function getBacth() {
        state.loading.batch = true;
        const { data } = await getNetDict({ code: 'batch' })
        state.batchList = data || [];
        query.batch = state.batchList[0] || '';
        state.loading.batch = false;
    }
    // 获取省份
    async function getProvince() {
        state.loading.province = true;
        const { data } = await getNetDict({ code: 'province' })
        state.provinceList = data || [];
        state.loading.province = false;
    }
    // 获取城市
    async function getCity() {
        state.loading.city = true;
        const { data } = await getNetDict({ code: 'city', province: query.province ? [query.province] : [] })
        state.cityList = data || [];
        state.loading.city = false;
    }
    // 获取区县
    async function getDistrict() {
        state.loading.district = true;
        const params = { code: 'district' };
        params['province'] = query.province ? [query.province] : [];
        params['city'] = query.city ? [query.city] : [];
        const { data } = await getNetDict(params);
        state.districtList = data || [];
        state.loading.district = false;
    }
    // 获取街道
    async function getStreet() {
        state.loading.street = true;
        const params = { code: 'street' };
        params['province'] = query.province ? [query.province] : [];
        params['city'] = query.city ? [query.city] : [];
        params['district'] = query.district ? [query.district] : [];
        const { data } = await getNetDict(params);
        state.streetList = data || [];
        state.loading.street = false;
    }
    // 获取餐饮类型-菜系一级
    async function getCuisine() {
        state.loading.mainType = true;
        const { data } = await getNetDict({ code: 'cuisine' });
        state.cuisineList = data || [];
        state.loading.mainType = false;
    }
    // 获取菜系类型-菜系二级
    async function getCuisine1() {
        state.loading.cuisineType = true;
        const { data } = await getNetDict({ code: 'cuisine1', cuisine1: query.mainType });
        state.cuisine1List = data || [];
        state.loading.cuisineType = false;
    }
    // 获取菜系-菜系三级
    async function getCuisine2() {
        state.loading.cuisine = true;
        const params = { code: 'cuisine2', cuisine1: query.mainType, cuisine2: query.cuisineType };
        const { data } = await getNetDict(params);
        state.cuisineTypeList = data || [];
        state.loading.cuisine = false;
    }
    // 省份改变
    function changeProvince() {
        state.cityList = [];
        query.city = '';
        state.districtList = [];
        query.district = '';
        state.streetList = [];
        query.street = '';
        getCity()
    }
    // 城市改变
    function changeCity() {
        state.districtList = [];
        query.district = '';
        state.streetList = [];
        query.street = '';
        getDistrict()
    }
    // 区县改变
    function changeDistrict() {
        state.streetList = [];
        query.street = '';
        getStreet()
    }
    // 餐饮类型改变
    function changeMainType() {
        state.cuisine1List = [];
        state.cuisineTypeList = [];
        query.cuisineType = [];    // 清空菜系类型
        query.cuisine = [];        // 清空菜系已选
        getCuisine1();             // 获取菜系
        getCuisine2();             // 获取菜系明细
    }
    // 菜系类型改变
    function changeCuisineType() {
        state.cuisineTypeList = [];
        query.cuisine = [];        // 清空菜系已选
        getCuisine2();             // 获取菜系明细
    }

    onBeforeMount(() => {
        getBacth();   // 批次
        getProvince();// 省份
        // getCity();// 城市
        // getDistrict();// 区县
        // getStreet();// 街道
        getCuisine()  // 餐饮类型-菜系1级
        getCuisine1();// 菜系类型-菜系2级
        getCuisine2();// 菜系-菜系3级
    })

    return { state, changeProvince, changeDistrict, changeMainType, changeCuisineType, changeCity }
}