export default (indexState, hotState, mapRef, clearTable) => {
    function resetFn() {
        // 重置条件
        resetQuery()
        // 重置数据
        resetData()
    }
    // 重置条件
    function resetQuery() {
        indexState.query.province = '';//省份
        indexState.query.city = '';//城市
        indexState.query.mainType = [];//餐饮类型
        indexState.query.cuisineType = [];//菜系类型
        indexState.query.cuisine = [];//菜系
        indexState.query.trade_price_min = null;//客单价
        indexState.query.trade_price_max = null;
        indexState.query.chain = '';//是否连锁
        indexState.query.mall_flag = '';//门店类型
        indexState.query.distance_type = '500';//网格大小
        indexState.mapQuery.sortField = 'shop_num';// 排序字段
    }
    // 重置数据
    function resetData() {
        hotState.hotData = [];// 热力图数据
        hotState.top10 = [];//前十数据
        hotState.activeRow = null;//选中数据
        mapRef.value.resetMap()
        // 重置table
        clearTable()
        // window.location.reload()
    }
    return { resetFn }
}