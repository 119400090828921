<template>
    <div id="container"></div>
</template>

<script setup>
import AMapLoader from '@amap/amap-jsapi-loader';
import { onBeforeUnmount, onMounted } from 'vue';
import maker_red from '@/assets/img/maker_red.png';
import maker_blue from '@/assets/img/maker_blue.png';

const emits = defineEmits(['onMaker'])

let AMap = null;// 地图实例
let map = null; // 地图
let heatmap = null;// 热力图
const defaultZoom = 10;//默认缩放级别

// 加载地图
function loadMap() {
    AMapLoader.load({
        key: "b8740a32e7dbff3f65745a34c0c386a2", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.ToolBar', 'AMap.ControlBar', 'AMap.HawkEye'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((_AMap) => {
        AMap = _AMap;
        initMap()
    }).catch(() => {
        // ElMessage.error('地图加载失败，请刷新浏览器，或更换浏览器后重试')
    })
}

// 初始化地图
function initMap() {
    map = new AMap.Map("container", {  //设置地图容器id
        viewMode: "2D",                //是否为3D地图模式
        zoom: defaultZoom,             //初始化地图级别
        zooms: [8, 18],                //地图显示的缩放级别范围, 默认为 [2, 20] ，取值范围 [2 ~ 30]
    })
    // 地图图块加载完成后触发
    map.on('complete', () => {
        // 添加地图控件
        addControls()
    });
}

// 添加地图控件
function addControls() {
    // 比例尺
    map.addControl(new AMap.Scale());
    // 工具条
    map.addControl(new AMap.ToolBar({
        position: {
            top: '110px',
            right: '40px'
        }
    }));
    // 方向盘
    map.addControl(new AMap.ControlBar({
        position: {
            top: '10px',
            right: '10px',
        }
    }));
    // 鹰眼
    map.addControl(new AMap.HawkEye({
        opened: false
    }));
}

// 设置地图中心点
function setMapCenter(lng, lat) {
    map.setCenter([lng, lat], true)
}

// 批量标记点
function addMakers(makers = []) {
    map.clearMap();//移除所有覆盖物
    const arr = [];
    makers.forEach((item, index) => {
        const icon = item.myActive ? maker_blue : maker_red;
        let zIndex = item.myActive ? 2 : 1;;
        const m = new AMap.Marker({
            content: `<div class="my_maker"><img src="${icon}" /><p>${index + 1}</p></div>`,
            position: new AMap.LngLat(item.lng_center, item.lat_center),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            anchor: 'bottom-center',
            zIndex: zIndex,
            myIndex: index
        });
        // 点击事件
        m.on('click', onMakerHandle);
        arr.push(m)
    })
    map.add(arr);
}

// 标记点点击事件
function onMakerHandle(ev) {
    emits('onMaker', ev.target._originOpts.myIndex)
}

// 添加热力图
function addHeatMap(points) {
    heatmap && heatmap.setMap(null);
    heatmap = null;
    map.plugin(["AMap.HeatMap"], () => {
        // 在地图对象叠加热力图
        heatmap = new AMap.HeatMap(map, {
            radius: 40, //给定半径
            opacity: [0, 0.8],
            // gradient: {
            //     0.5: 'blue',
            //     0.65: 'rgb(117,211,248)',
            //     0.7: 'rgb(0, 255, 0)',
            //     0.9: '#ffea00',
            //     1.0: 'red'
            // }
        });
        // 设置热力图数据集
        heatmap.setDataSet({
            data: points,
            max: points[0].count
        });
    });
    map.setZoom(defaultZoom, true)
}

// 打开信息框
function addInfoWindow(row) {
    const infoWindow = new AMap.InfoWindow({
        content: `<div>门店数：${row.shop_num || '/'}<br>关店数：${row.close_shop_num || '/'}<br>新店数：${row.new_shop_num || '/'}<br>评论数：${row.comments_num || '/'}</div>`,
        anchor: 'top-left',
    });
    // 在地图上打开信息窗体
    infoWindow.open(map, [row.lng_center, row.lat_center]);
}

// 重置地图
function resetMap() {
    heatmap = null;
    map && map.destroy();
    map = null;
    initMap()
}

onMounted(() => {
    loadMap()
})

onBeforeUnmount(() => {
    map && map.destroy();
    AMap = null;
    map = null;
    heatmap = null;
})

defineExpose({ setMapCenter, addMakers, addHeatMap, addInfoWindow, resetMap })
</script>

<style lang="scss" scoped>
#container {
    width: 100%;
    height: 100%;
}

:deep(.my_maker) {
    width: 25px;
    height: 38px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    p {
        color: #fff;
        display: block;
        position: relative;
        z-index: 2;
        text-align: center;
        font-size: 12px;
        padding-top: 6px;
    }
}
</style>