<template>
    <div class="home">
        <!-- 头部 -->
        <my-header @outLoginFun='outLoginFun'></my-header>
        <!-- 数据范围筛选 -->
        <div class="row_box">
            <div class="tit_box">
                <small-title tit="数据范围筛选"></small-title>
            </div>
            <!-- 参数 -->
            <div class="search_box">
                <div class="li">
                    <h6>批次：</h6>
                    <el-select v-model="state.query.batch" placeholder="请选择" :loading="paramsState.loading.batch">
                        <el-option v-for="item in paramsState.batchList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>省份：</h6>
                    <el-select v-model="state.query.province" placeholder="全部" filterable clearable
                        @change="changeProvince" :loading="paramsState.loading.province">
                        <el-option v-for="item in paramsState.provinceList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>城市：</h6>
                    <el-select v-model="state.query.city" placeholder="请先选择省份" filterable clearable
                        :loading="paramsState.loading.city" @change="changeCity">
                        <el-option v-for="item in paramsState.cityList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>区县：</h6>
                    <el-select v-model="state.query.district" placeholder="请先选择城市" filterable clearable
                        :loading="paramsState.loading.district" @change="changeDistrict">
                        <el-option v-for="item in paramsState.districtList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>街道：</h6>
                    <el-select v-model="state.query.street" placeholder="请先选择区县" filterable clearable
                        :loading="paramsState.loading.street">
                        <el-option v-for="item in paramsState.streetList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>餐饮类型：</h6>
                    <el-select v-model="state.query.mainType" class="m-2" placeholder="全部" filterable clearable multiple
                        collapse-tags @change="changeMainType">
                        <el-option v-for="item in paramsState.cuisineList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>菜系类型：</h6>
                    <el-select v-model="state.query.cuisineType" class="m-2" placeholder="全部" filterable clearable
                        multiple collapse-tags @change="changeCuisineType">
                        <el-option v-for="item in paramsState.cuisine1List" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>菜系：</h6>
                    <el-select v-model="state.query.cuisine" class="m-2" placeholder="全部" filterable clearable multiple
                        collapse-tags>
                        <el-option v-for="item in paramsState.cuisineTypeList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>客单价：</h6>
                    <div class="int_box">
                        <el-input type="number" v-model="state.query.trade_price_min"></el-input>-
                        <el-input type="number" v-model="state.query.trade_price_max"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>是否连锁：</h6>
                    <el-radio-group v-model="state.query.chain">
                        <el-radio-button label="">全部</el-radio-button>
                        <el-radio-button label="Y">是</el-radio-button>
                        <el-radio-button label="N">否</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="li">
                    <h6>门店类型：</h6>
                    <el-radio-group v-model="state.query.mall_flag">
                        <el-radio-button label="">全部</el-radio-button>
                        <el-radio-button label="商场店">商场</el-radio-button>
                        <el-radio-button label="路边店">路边店</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="li">
                    <h6>网格大小：</h6>
                    <el-radio-group v-model="state.query.distance_type">
                        <el-radio-button label="500">500M</el-radio-button>
                        <el-radio-button label="1000">1KM</el-radio-button>
                        <el-radio-button label="2000">2KM</el-radio-button>
                        <el-radio-button label="3000">3KM</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="btn_box">
                <el-button type="info" @click="resetFn">重置</el-button>
                <el-button type="primary" @click="onSearch">搜索</el-button>
            </div>
        </div>
        <!-- 内容 -->
        <div class="row_box">
            <div class="tit_box">
                <small-title :tit="smallTitle"></small-title>
                <div class="ri_box">
                    <el-button type="primary" v-if="state.viewType === 'table'" @click="state.viewType = 'map'">热力图
                    </el-button>
                    <el-button type="primary" v-if="state.viewType === 'map'" @click="state.viewType = 'table'">详细数据
                    </el-button>
                    <el-button type="primary" @click="exportExcel">导出excel</el-button>
                </div>
            </div>
            <!-- 地图 -->
            <div class="map_box" v-show="state.viewType === 'map'">
                <cover-table v-model:sortField="state.mapQuery.sortField" :list="hotState.top10" @onTable="onTable"
                    :activeRow="hotState.activeRow">
                </cover-table>
                <map-container ref="mapRef" @onMaker="onMaker"></map-container>
            </div>
            <!-- 表格 -->
            <div class="table_box" v-show="state.viewType === 'table'">
                <el-table :data="tableState.tableData" stripe border style="width: 100%" height="441">
                    <el-table-column prop="province" label="省份" align="center" />
                    <el-table-column prop="city" label="城市" align="center" />
                    <el-table-column prop="district" label="区县" align="center" />
                    <el-table-column prop="street" label="街道" align="center" />
                    <el-table-column prop="lat_center" label="中心点(Lat)" align="center" />
                    <el-table-column prop="lng_center" label="中心点(Lng)" align="center" />
                    <el-table-column prop="shop_num" label="门店数" align="center" />
                    <el-table-column prop="close_shop_num" label="关店数" align="center" />
                    <el-table-column prop="new_shop_num" label="新店数" align="center" />
                    <el-table-column prop="comments_num" label="评论数" align="center" />
                </el-table>
                <div class="no_data" v-if="tableState.tableData.length === 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div class="page_box">
                    <el-pagination layout="prev, pager, next" background :total="tableState.total"
                        :page-size="tableState.query.pageSize" v-model:current-page="tableState.query.pageNum"
                        @current-change="getTableData">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template> 

<script setup>
import MyHeader from "./MyHeader.vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import { computed, reactive, watch, ref } from "vue";
import { netExport } from "@/request/cityGridApi";
import outLoginHook from "@/utils/outLoginHook.js";
import useParams from "./useParams.js";
import { ElMessage } from "element-plus";
import { baseURL } from "@/request/http.js";
import { getStorage } from "@/utils";
import useTableData from './useTableData';
import MapContainer from './MapContainer.vue';// 地图容器
import CoverTable from './CoverTable.vue';// 地图容器
import useReset from './useReset.js';// 重置
import useHotData from './useHotData.js';//热力图页数据

const state = reactive({
    query: {
        batch: '',//批次
        province: '',//省份
        city: '',// 城市
        district: '',// 区县
        street: '',//街道
        mainType: [], //餐饮类型
        cuisineType: [],//菜系类型
        cuisine: [],//菜系
        trade_price_min: null,//客单价
        trade_price_max: null,
        chain: '',//是否连锁
        mall_flag: '',//门店类型
        distance_type: '500'// 网格大小
    },
    mapQuery: {
        sortField: 'shop_num',// 排序字段 shop_num/close_shop_num/new_shop_num/comments_num
    },
    viewType: 'map',// 显示类型 map table
});

// 显示内容标题
const smallTitle = computed(() => {
    const obj = { map: '热力图', table: '网格详细数据' }
    return obj[state.viewType]
})

// 地图组件ref
const mapRef = ref(null);

watch(() => state.mapQuery.sortField, () => {
    // 排序方式改变，重新获取数据并取前十
    getHotData()
})

// 点击搜索
function onSearch() {
    if (!state.query.batch) {
        ElMessage.error('请选择批次')
        return
    }
    if (!state.query.province) {
        ElMessage.error('请选择省份')
        return
    }
    if (!state.query.city) {
        ElMessage.error('请选择城市')
        return
    }
    state.viewType = 'map';
    getHotData();
}

// 表格数据
const { state: tableState, getTableData, clearTable, setTableData } = useTableData(state)

// 热力图数据
const { state: hotState, getHotData, onTable, onMaker } = useHotData(state, mapRef, setTableData)

// 导出
function exportExcel() {
    let tableData = [];
    switch (state.viewType) {
        case 'map':
            tableData = hotState.hotData || [];
            break;
        case 'table':
            tableData = tableState.tableData || [];
            break;
    }
    if (tableData.length === 0) {
        ElMessage.error("暂无数据，请搜索");
        return;
    }
    const token = getStorage("token");
    const href = `${baseURL}${netExport()}?token=${token}&sortField=${state.mapQuery.sortField}`;
    const a = document.createElement("a");
    a.href = href;
    a.download = href;
    a.click();
}

// 退出登录
const { outLoginFun } = outLoginHook();

// 条件选项
const { state: paramsState, changeProvince, changeCity, changeDistrict, changeMainType, changeCuisineType } = useParams(state);

// 重置
const { resetFn } = useReset(state, hotState, mapRef, clearTable);

</script>

<style lang="scss" scoped>
.home {
    padding: 40px;
    padding-top: 80px;

    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;

        .tit_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .search_box {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;
            padding-top: 20px;

            .li {
                margin-right: 20px;
                margin-bottom: 10px;

                h6 {
                    font-weight: normal;
                    color: #818a9b;
                    padding-bottom: 6px;
                    font-size: 14px;
                }

                .el-select {
                    width: 200px;
                }

                :deep(.el-input__inner) {
                    border-radius: 7px;
                }

                .int_box {
                    display: flex;
                    align-items: center;

                    .el-input {
                        width: 98px;
                    }
                }

                &.two_select {
                    .el-select {
                        width: 150px;
                        margin-right: 15px;
                    }
                }
            }
        }

        .btn_box {
            text-align: center;
            padding-top: 10px;
        }

        .table_box {
            position: relative;
            padding-top: 15px;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 100px;
            }
        }

        .map_box {
            border: 1px solid #f2f2f2;
            margin-top: 15px;
            height: 750px;
            border-radius: 4px;
            position: relative;
        }
    }

    .page_box {
        text-align: right;
        padding: 20px 0;
    }
}

:deep(.el-table__empty-text) {
    display: none;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
    background-color: #ddd;
    border-radius: 5px;
    cursor: pointer;
}
</style>
